export default defineAppConfig({
    shopZones: [
        {
            domain: "www.snubniprsteny4u.eu",
            shopZoneId: 4,
            locales: [
                { id: 1, languageId: 1, currencyId: 21, locale: 'cs-CZ', countrySign: 'CZ', currencySign: 'CZK', currencySignLocal: 'Kč', maxDigits: 0, flag: 'cz', bankAccount: '<div><span style="font-size:14px;font-family: verdana,geneva,sans-serif;line-height:32px;"><img style="margin-bottom: -8px;" width="24" src="https://www.sperky4u.eu/img/fio.png" /> Fio banka: <span style="font-weight: bold;">2801053718/2010</span></span><br /><span style="font-size:14px;font-family: verdana,geneva,sans-serif;line-height:32px;"><img style="margin-bottom: -3px;" width="24" src="https://www.sperky4u.eu/img/cs.png" /> Česká spořitelna: <span style="font-weight: bold;">5247635369/0800</span></span></div>' },
                { id: 2, languageId: 1, currencyId: 23, locale: 'sk', countrySign: 'SK', currencySign: 'EUR', currencySignLocal: '€', flag: 'sk', bankAccount: '<p><span style="font-size:14px;"><span style="font-family: verdana,geneva,sans-serif;">IBAN: <strong><span>CZ7620100000002601053721</span></strong></span></span></p><p><span style="font-size:14px;"><span style="font-family: verdana,geneva,sans-serif;">SWIFT: <strong>FIOBCZPPXXX</strong></span></span></p>' },
            ]
        }
    ],
    defaultShopZone: "www.snubniprsteny4u.eu",
    staticResourcesRoot: "https://cdn.snubniprsteny4u.eu",
    moduleMapping: {
        1: "default",
        2: "group",
        3: "ware",
        44: "emptyCart",
        23: "cart",
        5: "order",
        17: "thankyou",
        6: "infoPage",
        16: "search",
        46: "ringsConfigurator",
        43: "magazine",
        45: "trackingCode"
    },
    graphQL: {
        //endpointUrl: 'https://localhost:5029/graphql',
        endpointUrl: 'https://api7th.drivenet.cz/graphql',
        //hostHeader: 'api.snubniprsteny4u.eu'
        hostHeader: 'api.snubniprsteny4u.eu'
    },
    gtmId: 'GTM-TGDPLQDQ',
    wareList: {
        itemsOnPage: 30,
        searchItems: 10
    },
    magazineList: {
        itemsOnPage: 9
    },
    magazineCategoryIds: [4, 5, 6],
    configurator: {
        idGroupToTakeMaterialParametersFrom: 564
    },
    homePageGroupId: 1171,
    infoPageCustomLayoutType: 9,
    contactPhone: '+420 773 542 416',
    contactEmail: 'info@snubniprsteny4u.eu',
    fbUrl: 'https://www.facebook.com/SnubniPrsteny4u/',
    definedPageUrls: {
        cart: '/kosik',
        emptyCart: '/prazdny-kosik',
        order: '/objednavka',
        search: '/vyhledavani',
        sizeHelper: '/magazin/jak-zmerit-velikost-prstenu',
        privacy: '/ochrana-osobnich-udaju',
        terms: '/obchodni-podminky',
        wareOrder: '/razeni-zbozi',
        magazine: '/magazin'
    },
    primaryNavFirstLink: {
        text: 'Konfigurátor snubních prstenů',
        url: '/konfigurator'
    },
    parameterIds: {
        label: 41440,
        vendor: 41439,
        material: 41202,
        womenSize: 41279,
        menSize: 41278,
        engravingRows: 41618,
        engravingCharactersPerRow: 41617,
        ringSizeSpecial: 41631,
        ringServiceDiamonds: 41675,
        weight: 41226
    },
    serviceOptionIds: {
        ringSize: 258
    },
    basicParameterFilter: {
        discount: {
            urlParameter: '41440:akce',
            urlValue: '5992111:doprodej'
        }
    },
    groupIds: {
        snubniPrsteny: 1171,
        zasnubniPrsteny: 1254,
        krabickyNaSnubniPrsteny: 1159,
        krabickyNaZasnubniPrsteny: 1158
    },
    deliveryPeriodNotStored: 99,
    variantCodeTypeToDisplayInCart: 3,
    orderCountries: [
        { id: 3, name: 'Česká republika', localeId: 1 },
        { id: 4, name: 'Slovensko', localeId: 2 }
    ],
    deadlineHour: 12,
    ownPickupOrShippingSystemIds: [33, 98, 99],
    shippingAddressDeliveryIds: [31, 22, 29, 16, 23],
    shippingPickupIds: [13, 34, 33, 28],
    shippingOwnPickupIds: [6],
    paymentWireIds: [8, 14],
    paymentOnlineIds: [26],
    paymenCoDIds: [7, 11, 12, 13, 15, 16, 22],
    newsletterSubscribeSources: {
        footer: 1,
        watchdog: 2
    },
    bsWidgetShippings: {
        13: 'ZAS',
        34: 'CP_BDB',
        33: 'PPL_PS',
        28: 'ZAS',
	    18: 'DPD_PS'
    },
    variantSizeParameterIds: [41205, 41206, 41208, 41211, 41212, 41227, 41237, 41239, 41242, 41243, 41250, 41253, 41407, 41473, 41474, 41209, 41294, 41301, 41483, 41486, 41226],
    materialDescInfoGroupIds: [
        1171, //SNUBNI_PRSTENY
        1191, //SNUBNI_PRSTENY_CHIRURGICKA_OCEL
        //778, //SNUBNI_PRSTENY_DAMASTEEL_OCEL - do not have eqivalent in SNP group tree
        //565, //SNUBNI_PRSTENY_DARKOVE_KRABICKY - do not have eqivalent in SNP group tree
        1609, //SNUBNI_PRSTENY_KERAMIKA
        1227, //SNUBNI_PRSTENY_STRIBRO
        //810, //SNUBNI_PRSTENY_TISTEN - do not have eqivalent in SNP group tree
        1246, //SNUBNI_PRSTENY_TITAN
        1250  //SNUBNI_PRSTENY_WOLFRAM
    ],
    keypadSymbols: [
        '①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫', 'ⓕ', 'ⓖ', '', '',
        '', '', '', '', '', '', '', '', '', '', '', '', '', '⑬', 'ⓙ', 'ⓚ',
        '', '', '⑭', '⑮', '⑯', 'Ⓐ', 'Ⓑ', 'Ⓒ', 'ⓜ', 'ⓝ', 'Ⓓ', 'Ⓔ', 'Ⓕ', 'Ⓖ', 'Ⓗ', 'Ⓘ',
        'Ⓙ', 'Ⓚ', 'Ⓛ', 'Ⓜ', 'Ⓝ', 'Ⓞ', 'Ⓟ', 'Ⓠ', '⒥', '⒦', '', '', '', '', '', '',
        '', '', 'Ⓡ', 'Ⓢ', 'Ⓣ', 'Ⓤ', 'Ⓥ', 'Ⓦ', 'Ⓧ', 'Ⓨ', 'Ⓩ', '⑴', '⑵', '⑶', '⑷', '⑸',
        '⑹', '⑺', '⑻', '⑼', '⑽', '⑾', '⑿', '⒀', '⒁', '⒂', '⒃', '⒄', '⒅', '⒆', '⒇', '⒜',
        '⒝', '⒞', '⒟', '⒠', '⒡', '⒢', '⒣', '⒤', '⒧', '⒨', '⒩', '', '', 'ⓐ', 'ⓑ', 'ⓒ',
        'ⓓ', 'ⓔ', 'ⓗ', 'ⓘ', 'ⓛ', '', '', '', '', '', '', '', '', '', '', '',
        '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '',
        '', '', '', '', '', '', '', '', '', '', '', '', '', ''
    ],
    serviceFonts: [
        'Affection.ttf',
        'Allura.ttf',
        'Amazone.ttf',
        'ARNO.OTF',
        'Aromia.otf',
        'Averta.otf',
        'Ballroom.ttf',
        'Beautiful.ttf',
        'Bohemian typewriter.ttf',
        'Bold & Stylish.ttf',
        'Book Antiqua.TTF',
        'Brilliant.ttf',
        'Centeria.otf',
        'Century Schoolbook.TTF',
        'DESYREL.ttf',
        'Dynalight.ttf',
        'Elfský font 1.ttf',
        'Elfský font 2.ttf',
        'Envy.TTF',
        'euro.ttf',
        'Fondamento.ttf',
        'Frivolity.otf',
        'gabriola.ttf',
        'Galberik.otf',
        'Garamond.TTF',
        'Garden.otf',
        'Great.ttf',
        'Gunny.TTF',
        'Harman.otf',
        'Heartache.ttf',
        'Housegrind.ttf',
        'Hunting.ttf',
        'I Miss Your Kiss.ttf',
        'Italianno.otf',
        'Jane.ttf',
        'Kastler Light.TTF',
        'Kastler.ttf',
        'Kiss Me.ttf',
        'Koala.TTF',
        'Lanara.ttf',
        'Lifehack.otf',
        'Lithos.otf',
        'Lovely Day.ttf',
        'Merveille.ttf',
        'Miama.otf',
        'Monotype.ttf',
        'Montez.ttf',
        'Nuance.TTF',
        'October.ttf',
        'Odstemplik.otf',
        'Oktah.ttf',
        'Optima.otf',
        'Parisienne.ttf',
        'Play.ttf',
        'promocyja.ttf',
        'Quiche.otf',
        'Runový font 1.ttf',
        'Runový font 2.ttf',
        'Ryker.otf',
        'Sacramento.ttf',
        'Segoe Script.ttf',
        'Segoe.ttf',
        'Signet Roundhand.TTF',
        'Skarpa.ttf',
        'Skolacek.otf',
        'Small Town.ttf',
        'Stalemate.ttf',
        'Stylish Calligraphy.ttf',
        'tengwarfeanorregular.ttf',
        'Trakya.otf',
        'TrueNorth.otf',
        'Vadelma.otf',
        'Watermelon.ttf',
        'Wedding Bells.ttf'
    ],
    serviceTextRenderUrl: 'https://www.sperky4u.eu/services.ashx?service=text&static=true&font={0}&text={1}&align=C',
    breakpoints: {
        desktop: 1024
    },
    errorLogging: {
        serviceEndpointUrl: 'https://adminer.drivenet.cz/api/event',
        apiKey: '840105E5FF314BDBADC3D5D7B73F85FE'
    }
})