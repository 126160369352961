export function useUtils() {
    const route = useRoute();

    const isDeepEqual = (x: Object, y: Object) => {
        if (x === y) {
            return true;
        }
        else if ((typeof x == "object" && x != null) && (typeof y == "object" && y != null)) {
            if (Object.keys(x).length != Object.keys(y).length)
                return false;

            for (var prop in x) {
                if (y.hasOwnProperty(prop)) {
                    if (!isDeepEqual(x[prop], y[prop]))
                        return false;
                }
                else
                    return false;
            }

            return true;
        }
        else
            return false;
    }

    const getGroupPageNumber = () => {

        let result = parseInt(route.query.p) || 1;

        return result;
    }

    const gtmDataLayerPush = (object: Object) => {
        if (process.client && dataLayer) {
            dataLayer.push(object);
        }
    }

    const slugify = (text: String) => {
        if (!text) return '';

        // make lower case and trim
        var slug = text.toLowerCase().trim();

        // remove accents from charaters
        slug = slug.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

        // replace invalid chars with spaces
        slug = slug.replace(/[^a-z0-9\s-]/g, ' ').trim();

        // replace multiple spaces or hyphens with a single hyphen
        slug = slug.replace(/[\s-]+/g, '-');

        return slug;
    }

    const getWareImageUrl = (imageId: Number, wareName: String, suffix: String) => {
        const slug = useUtils().slugify(wareName);
        const result = `${useAppConfig().staticResourcesRoot}/wareImages/${slug}-${imageId.toString().padStart(6, '0')}_${suffix}.jpg`;

        return result;
    }

    const capitalize = (text: String) => {

        if (text == null || typeof text === 'undefined') {
            return '';
        }

        let result = text.charAt(0).toUpperCase() + text.slice(1);
        return result;
    }

    const scrollTo = (element: HTMLElement, offset: number | undefined = 15) => {
        window.scrollTo({ top: element.offsetTop - offset, behavior: 'smooth' });
    }

    const bendWord = (word: String, count: number) => {
        let bentWord = word;

        if (word === 'minuta') {
            if (count > 4) {
                bentWord = 'minut';
            }
            else if (count > 1) {
                bentWord = 'minuty';
            }
        }
        else if (word === 'den') {
            if (count > 4) {
                bentWord = 'dní';
            }
            else if (count > 1) {
                bentWord = 'dny';
            }
        }

        const result = `${count} ${bentWord}`;

        return result;
    }

    const extractDate = (dateTime: Date): Date => {

        const result = new Date(dateTime.getFullYear(), dateTime.getMonth(), dateTime.getDate());

        return result;
    }

    const addDaysToDate = (date: Date, days: number): Date => {
        const result = new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);

        return result;
    }

    const formatDate = (date: Date | null) => {

        let result;

        if (!date) {
            result = 'na dotaz';
        }

        if (extractDate(date).getTime() === extractDate(new Date()).getTime()) {
            result = 'dnes';
        }
        else if (extractDate(date).getTime() === addDaysToDate(new Date(), 1).getTime()) {
            result = 'zítra';
        }
        else if (extractDate(date).getTime() === addDaysToDate(new Date(), 2).getTime()) {
            result = 'pozítří';
        }
        else {
            result = date.toLocaleDateString(useLocale().getLocale().locale);
        }

        return result;
    }

    const camelize = (string: String) => {
        const result = string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
        return result;
    }

    const findLongestCommonSubstring = (strings: Array<string>) => {

        if (!strings || strings.length === 0) {
            return '';
        }

        let iChar, iWord,
            refWord = strings[0],
            lRefWord = refWord.length,
            lWords = strings.length;
        for (iChar = 0; iChar < lRefWord; iChar += 1) {
            for (iWord = 1; iWord < lWords; iWord += 1) {
                if (refWord[iChar] !== strings[iWord][iChar]) {
                    let res = refWord.substring(0, iChar);
                    if (res.includes("-")) {
                       res = res.substring(0, res.lastIndexOf("-")); 
                    }
                    return res;
                }
            }
        }
        return refWord;
    }

    const setBodyScrollable = (scrollable: boolean) => {
        if (scrollable) {
            document.getElementsByTagName('body')[0].classList.remove('overflow-hidden');
        }
        else {
            document.getElementsByTagName('body')[0].classList.add('overflow-hidden');
        }
    }

    return {
        isDeepEqual,
        getGroupPageNumber,
        gtmDataLayerPush,
        slugify,
        getWareImageUrl,
        capitalize,
        scrollTo,
        bendWord,
        extractDate,
        addDaysToDate,
        formatDate,
        camelize,
        findLongestCommonSubstring,
        setBodyScrollable
    }
}